import React, { FC } from "react";
import { cleanURL } from "../../helpers";
import { RoundedElementProps } from "../types";
import "./styles.scss";

const RoundedElement: FC<RoundedElementProps> = ({
  text,
  active,
  onChange,
}) => (
  <div
    id={cleanURL(text)}
    onClick={() => onChange(text)}
    className={`${active ? "rounded-element active" : "rounded-element"}`}
  >
    {text}
  </div>
);

export default RoundedElement;
