import React, { FC, useEffect, useState } from "react";
import { cleanURL } from "../../helpers";
import { arrowDownIcon } from "../../images/svgIcons";
import { FieldsProps } from "../../types";
import RoundedElement from "../roundedElement";
import "./styles.scss";

const Fields: FC<FieldsProps> = ({ lists, active, onChange, className }) => {
  const [scroll, setScroll] = useState(0);
  const [isScroll, setIsScroll] = useState(false);

  useEffect(() => {
    const el = document.querySelector(`.${className ? className : "fields"}`);
    if (el) {
      if (el.scrollWidth - el.clientWidth === el.scrollLeft) return;
      setScroll(el.scrollWidth - el.clientWidth);
      setIsScroll(el.scrollWidth - el.clientWidth === el.scrollLeft);
    }
  }, []);

  const scrollLeft = () => {
    if (!scroll) return;
    const el = document.querySelector(`.${className ? className : "fields"}`);
    if (el) {
      if (el.scrollLeft > 0) el.scrollLeft -= 70;
      setScroll(el.scrollLeft);
      setIsScroll(el.scrollWidth - el.clientWidth === el.scrollLeft);
    }
  };

  const scrollRight = () => {
    const el = document.querySelector(`.${className ? className : "fields"}`);
    if (el) {
      if (el.scrollWidth - el.clientWidth === el.scrollLeft) return;
      if (el.scrollLeft <= el.scrollWidth) el.scrollLeft += 70;
      setScroll(el.scrollLeft);
      setIsScroll(el.scrollWidth - el.clientWidth === el.scrollLeft);
    }
  };

  const change = (name: string) => {
    if (active === cleanURL(name) || (!active && name === "All")) return;
    onChange(name);
    const el = document.querySelector(`.${className ? className : "fields"}`);
    if (el) {
      setScroll(el.scrollLeft);
    }
  };

  return (
    <div className="fields-wrapper px-1">
      <div
        onClick={scrollLeft}
        className={`icon left-icon ${!scroll ? "disabled" : ""}`}
      >
        {arrowDownIcon}
      </div>
      <div
        className={`fields overflow-auto no-wrap mx-2 ${
          className ? className : ""
        }`}
      >
        {lists.map((list: { name: string; id: number }) => (
          <RoundedElement
            text={list.name}
            active={
              active === cleanURL(list.name) || (!active && list.name === "All")
            }
            onChange={() => {
              change(list.name);
            }}
          />
        ))}
      </div>
      <div
        onClick={scrollRight}
        className={`icon right-icon ${isScroll ? "disabled" : ""}`}
      >
        {arrowDownIcon}
      </div>
    </div>
  );
};
export default Fields;
