import React, {useState, FC} from 'react';
import Layout from '../../layout/layout';
import {PageHero} from '../../page-hero';
import Section from '../../section';
import Input from '../../input';
import Search from '../../../images/svgIcons/search.svg';
import './styles.scss';
import Fields from '../../filter-fields';
import AutomationCardsContainer from '../automation-cards-container';
import {IPageContext, ITransformAutomation} from '../../../types';
import {navigate} from 'gatsby';
import {useEffect} from 'react';
import NothingToShow from '../../nothingToShow';
import {Helmet} from 'react-helmet';
import {cleanURL} from '../../../helpers';

const Automations: FC<IPageContext> = ({pageContext}) => {
  //pageContext
  const typeId = pageContext.typeId;
  const categoryId = pageContext.categoryId;
  const automationsList = pageContext.result.automations;
  const typeArr = (pageContext.result.types.length && pageContext.result.types) || [];
  const types = [{name: 'All', id: 0}, ...typeArr];
  const categoryArr = (pageContext.result.categories.length && pageContext.result.categories) || [];
  const categories = [{name: 'All', id: 0}, ...categoryArr];
  //--------------------------------------------------------------

  //states
  const [automations, setAutomations] = useState(automationsList);
  const [activeType, setActiveType] = useState(typeId ? cleanURL(typeId) : '');
  const [activeCategory, setActiveCategory] = useState(categoryId ? cleanURL(categoryId) : '');
  const [inputVal, setInputVal] = useState<string>('');
  //--------------------------------------------------------------

  //helmet information
  const title = `Automations${typeId ? '/' + typeId : ''}${categoryId ? '/' + categoryId : ''}`;
  const keywords = `Automations ${typeId ? typeId : ''} ${categoryId ? categoryId : ''}`;
  //--------------------------------------------------------------

  //useEffects
  useEffect(() => {
    activeType && document.getElementById(String(activeType))?.scrollIntoView(true);
    activeCategory && document.getElementById(String(activeCategory))?.scrollIntoView(true);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!inputVal) {
      setAutomations(automationsList);
      return;
    }
    const newAutomations: Array<ITransformAutomation> = [];
    automationsList.map(data => {
      const newList =
        data &&
        data.list.filter(
          (item: {short_description: string; long_description: string; name: string}) =>
            item.short_description.toLowerCase().includes(inputVal) ||
            item.long_description.toLowerCase().includes(inputVal) ||
            item.name.toLowerCase().includes(inputVal),
        );
      //@ts-ignore
      if (newList && newList.length) newAutomations.push({list: newList});
    });
    setAutomations(newAutomations);
  }, [inputVal, automationsList]);

  useEffect(() => {
    activeCategory && activeType && navigate(`/automations/${activeType}/${activeCategory}/`);
    activeCategory && !activeType && navigate(`/automations/${activeCategory}/`);
    !activeCategory && activeType && navigate(`/automations/${activeType}/`);
    !activeCategory && !activeType && navigate(`/automations/`);
  }, [activeType, activeCategory]);
  //--------------------------------------------------------------
  return (
    <>
      <Layout>
        <PageHero
          title="100+ Automations to run time-consuming sales, marketing, and research tasks at scale"
          titleSmall
          subtitle2={
            'Tap into the true power of the cloud with 1-click access to data, analysis, research, and AI-powered services you can combine to create the perfect workflow in minutes.'
          }
        />
        <Section>
          <div className="search-wrapper">
            <Input
              placeholder="Search Automation..."
              onChange={e => setInputVal(e.toLowerCase())}
              leftIcon={<img alt="search" src={Search} />}
            />
          </div>
          <div className="search-property">
            <div className="overflow-auto no-wrap types" id="type">
              {types && !!types.length && (
                <Fields
                  lists={types}
                  onChange={val => (val === 'All' ? setActiveType('') : setActiveType(cleanURL(val)))}
                  active={activeType}
                  className="types-fields"
                />
              )}
            </div>
            <div className="overflow-auto no-wrap categories">
              {categories && !!categories.length && (
                <Fields
                  lists={categories}
                  onChange={val => (val === 'All' ? setActiveCategory('') : setActiveCategory(cleanURL(val)))}
                  active={activeCategory}
                  className="categories-fields"
                />
              )}
            </div>
            {!automations.length ||
            (automations.length === 1 && automations[0] && automations[0].list && !automations[0].list.length) ? (
              <NothingToShow />
            ) : (
              automations.map(item => <AutomationCardsContainer inputVal={inputVal} data={item} />)
            )}
          </div>
        </Section>
      </Layout>
    </>
  );
};

export default Automations;
